import React, { useState } from 'react';
import { PaymentForm, CreditCard } from 'react-square-web-payments-sdk';
import axios from 'axios';

const SquarePayment = () => {
  const [paymentStatus, setPaymentStatus] = useState('');

  const submitFormAndSendEmail = async (token) => {
    const companyDetails = JSON.parse(localStorage.getItem('companyDetails'));
    const owners = JSON.parse(localStorage.getItem('owners'));

    const formData = new FormData();

    // Append company details, including the new "State of Registration"
    formData.append('companyName', companyDetails.companyName);
    formData.append('companyEin', companyDetails.companyEIN);
    formData.append('companyAddress', companyDetails.companyAddress);
    formData.append('companyCity', companyDetails.companyCity); // Append city
    formData.append('companyState', companyDetails.companyState); // Append state
    formData.append('companyZip', companyDetails.companyZip); // Append ZIP code
    formData.append('companyEmail', companyDetails.companyEmail); // Append email
    formData.append('companyPhone', companyDetails.companyPhone); // Append phone
    formData.append('companyStateOfRegistration', companyDetails.companyStateOfRegistration); // Append state of registration
    formData.append('token', token); // Add payment token

    // Append owner details, including the base64 encoded file (stored in localStorage)
    owners.forEach((owner, index) => {
      formData.append(`owners[${index}][firstName]`, owner.firstName);
      formData.append(`owners[${index}][middleName]`, owner.middleName);
      formData.append(`owners[${index}][lastName]`, owner.lastName);
      formData.append(`owners[${index}][dob]`, owner.dob);
      formData.append(`owners[${index}][currentAddress]`, owner.currentAddress);
      formData.append(`owners[${index}][city]`, owner.city); // Owner's city
      formData.append(`owners[${index}][state]`, owner.state); // Owner's state
      formData.append(`owners[${index}][zip]`, owner.zip); // Owner's ZIP code
      formData.append(`owners[${index}][idFile]`, owner.idFile); // Append the base64 file directly
    });

    console.log([...formData.entries()]);  // Log the formData before submitting

    try {
      const response = await axios.post("https://pioneeriya.com/mybackend/api/sendForm.php", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      });
      console.log(response.data);

      if (response.data.status === 'success') {
        setPaymentStatus('Form submitted and payment processed successfully!');
      } else {
        setPaymentStatus(`Failed to submit form or process payment: ${response.data.message}`);
      }
    } catch (error) {
      console.error("Error submitting form and sending email:", error);
      setPaymentStatus('Error submitting form or sending email.');
    }
  };

  return (
    <div>
      <h1>Square Payment Integration</h1>
      <PaymentForm
        applicationId="sandbox-sq0idb-R1GZSK9-t3vuey4nAV9RyQ"
        locationId="L5Q71YYD1KCZ1"
        cardTokenizeResponseReceived={(token, buyer) => {
          if (token) {
            setPaymentStatus('Payment successful! Submitting form...');
            submitFormAndSendEmail(token.token); // Submit form after payment
          } else {
            setPaymentStatus('Payment failed. Please try again.');
          }
        }}
        createVerificationDetails={() => {
          const companyDetails = JSON.parse(localStorage.getItem('companyDetails'));
          return {
            amount: '100.00',
            currencyCode: 'USD',
            intent: 'CHARGE',
            billingContact: {
              familyName: 'Doe',
              givenName: 'John',
              email: companyDetails.companyEmail, // Use email from BOIRForm
              addressLines: [companyDetails.companyAddress],
              city: companyDetails.companyCity, // Use city from BOIRForm
              state: companyDetails.companyState, // Use state from BOIRForm
              postalCode: companyDetails.companyZip, // Use ZIP code from BOIRForm
              countryCode: 'US',
              phone: companyDetails.companyPhone, // Use phone from BOIRForm
            },
          };
        }}
      >
        <CreditCard />
        <button style={{ marginTop: '20px', padding: '10px', fontSize: '16px' }}>
          Pay Now
        </button>
      </PaymentForm>
      <p>{paymentStatus}</p>
    </div>
  );
};

export default SquarePayment;
